.container {
    background: url("./bg1.jpg") no-repeat center center fixed;
    background-size: cover;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.container h1 {
    font-size: 50px;
    color: #fff;
}
.formContainer {
    background: url("./formBg.png") no-repeat;
    width: 416px;
    height: 290px;
    padding: 60px 25px;
}
@media screen and (max-width: 500px) {
    .formContainer {
        width: 80%;
    }
}
.formItem :global(.ant-input) {
    background-color: transparent;
    border-radius: 2px;
    height: 40px;
    color: #fff;
    padding-left: 40px !important;
}
.formItem .icon {
    color: #fff;
    font-size: 18px;
}
.formContainer button {
    width: 200px;
    margin-left: 83px;
}
@media screen and (max-width: 500px) {
    .formContainer button {
        width: 100%;
        margin: auto;
    }
}
.footer {
    position: fixed;
    bottom: 10px;
}