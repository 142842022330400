.searchForm :global(.ant-form-item) {
    display: flex;
}

.searchForm :global(.ant-form-item-control-wrapper) {
    flex: 1;
}

.searchForm :global(.ant-form-item-label) {
    padding-right: 8px;
}

.statisticsCard {
    position: relative;
    text-align: center;
}

.statisticsCard span {
    color: rgba(0, 0, 0, .45);
    display: inline-block;
    line-height: 22px;
    margin-bottom: 4px;
}

.statisticsCard p {
    font-size: 24px;
    line-height: 32px;
    margin: 0;
}

.statisticsCard em {
    background-color: #e8e8e8;
    position: absolute;
    height: 56px;
    width: 1px;
    top: 0;
    right: 0;
}
