.container {
    padding: 24px;
}
.container div {
    box-sizing: border-box;
}
.item {
    display: flex;
    flex-direction: row;
    margin-top: 24px;
}
.key {
    flex: 1;
    padding-right: 10px;
    text-align: right;
    font-size: 16px;
    line-height: 32px;
    color: rgba(0, 0, 0, 0.85);
}
.value {
    flex: 2;
    line-height: 32px;
}
.value input {
    font-size: 16px;
}